<template>
  <div class="px-3">
    <!--Field Setting  Start -->
    <div class="card-body px-0">
      <div id="LabelFieldSettingAccordion">
        <div class="headingText" id="headingThree">
          <strong class="">
            <span>Field Setting</span>

            <a
              block
              v-b-toggle.labelSetting
              style="float: right; padding-right: 20px; cursor: pointer"
              class="arrow-icon-down"
              data-toggle="collapse"
              data-target="#labelSetting"
              aria-expanded="false"
              aria-controls="labelSetting"
            >
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </a>
          </strong>
        </div>
        <hr />
        <b-collapse id="labelSetting" accordion="my-accordion" role="tabpanel">
          <div class="d-flex flex-column justify-content-center">
            <div
              class="mb-2 border-bottom pb-3"
              :style="
                !block.showAddressLine1 &&
                checkExistingAddresField(`AddressLine1`)
                  ? 'opacity:0.4;'
                  : null
              "
            >
              <div class="  ">
                <div class="d-flex align-items-center pb-2">
                  <span class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="
                        !block.showAddressLine1 &&
                        checkExistingAddresField(`AddressLine1`)
                      "
                      :id="`show-address1-${Date.now()}`"
                      v-model="block.showAddressLine1"
                    />
                    <label
                      class="custom-control-label"
                      :for="`show-address1-${Date.now()}`"
                    ></label>
                  </span>

                  <p class="m-0 editorStyleTitle">Address 1</p>
                </div>

                <div class="">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium"
                      >Is Address 1 required
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`address1-${Date.now()}`"
                        v-model="block.isAddressLine1Required"
                      />
                      <label
                        class="custom-control-label"
                        :for="`address1-${Date.now()}`"
                      ></label>
                    </span>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium">
                      Show Label
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`label-address1-${Date.now()}`"
                        v-model="block.showAddressLine1Label"
                      />
                      <label
                        class="custom-control-label"
                        :for="`label-address1-${Date.now()}`"
                      ></label>
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-if="block.showAddressLine1Label"
                  >
                    <strong>Label Text :</strong>
                    <b-form-input
                      placeholder="Address"
                      v-model="block.AddressLine1Label"
                      class="w-50"
                    ></b-form-input>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-1"
                  >
                    <strong>Placeholder Text :</strong>
                    <b-form-input
                      placeholder="Address "
                      v-model="block.AddressLine1Placeholder"
                      class="w-50"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-2 border-bottom pb-3" :style="
                !block.showAddressLine2 &&
                checkExistingAddresField(`AddressLine2`)
                  ? 'opacity:0.4;'
                  : null
              ">
              <div class="  ">
                <div class="d-flex align-items-center pb-2">
                  <span class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="
                        !block.showAddressLine2 &&
                        checkExistingAddresField(`AddressLine2`)
                      "
                      :id="`show-address2-${Date.now()}`"
                      v-model="block.showAddressLine2"
                    />
                    <label
                      class="custom-control-label"
                      :for="`show-address2-${Date.now()}`"
                    ></label>
                  </span>
                  <p class="m-0 editorStyleTitle">Address Line 2</p>
                </div>
                <div class="">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium"
                      >Is Address 2 required
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`address2-${Date.now()}`"
                        v-model="block.isAddressLine2Required"
                      />
                      <label
                        class="custom-control-label"
                        :for="`address2-${Date.now()}`"
                      ></label>
                    </span>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium">
                      Show Label
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`label-address2-${Date.now()}`"
                        v-model="block.showAddressLine2Label"
                      />
                      <label
                        class="custom-control-label"
                        :for="`label-address2-${Date.now()}`"
                      ></label>
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-if="block.showAddressLine2Label"
                  >
                    <strong>Label Text :</strong>
                    <b-form-input
                      placeholder="Address"
                      v-model="block.AddressLine2Label"
                      class="w-50"
                    ></b-form-input>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-1"
                  >
                    <strong>Placeholder Text :</strong>
                    <b-form-input
                      placeholder="Address "
                      v-model="block.AddressLine2Placeholder"
                      class="w-50"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-2 border-bottom pb-3" :style="
                !block.showCity &&
                checkExistingAddresField(`City`)
                ? 'opacity:0.4'
                  : null
              "
                >
              <div class="  ">
                <div class="d-flex align-items-center pb-2">
                  <span class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      :disabled="
                        !block.showCity &&
                        checkExistingAddresField(`City`)
                      "
                      class="custom-control-input"
                      :id="`show-city-${Date.now()}`"
                      v-model="block.showCity"
                    />
                    <label
                      class="custom-control-label"
                      :for="`show-city-${Date.now()}`"
                    ></label>
                  </span>
                  <p class="m-0 editorStyleTitle">City/Town</p>
                </div>
                <div class="">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium"
                      >Is City required
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                       
                        class="custom-control-input"
                        :id="`city-${Date.now()}`"
                        v-model="block.isCityRequired"
                      />
                      <label
                        class="custom-control-label"
                        :for="`city-${Date.now()}`"
                      ></label>
                    </span>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium">
                      Show Label
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`label-city-${Date.now()}`"
                        v-model="block.showCityLabel"
                      />
                      <label
                        class="custom-control-label"
                        :for="`label-city-${Date.now()}`"
                      ></label>
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-if="block.showCityLabel"
                  >
                    <strong>Label Text :</strong>
                    <b-form-input
                      placeholder="Address"
                      v-model="block.CityLabel"
                      class="w-50"
                    ></b-form-input>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-1"
                  >
                    <strong>Placeholder Text :</strong>
                    <b-form-input
                      placeholder="Address "
                      v-model="block.CityPlaceholder"
                      class="w-50"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-2 border-bottom pb-3" :style="
                !block.showState &&
                checkExistingAddresField(`State`)
                ? 'opacity:0.4'
                  : null
              "
                >
              <div class="  ">
                <div class="d-flex align-items-center pb-2">
                  <span class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="
                        !block.showState &&
                        checkExistingAddresField(`State`)
                      "
                      :id="`show-state-${Date.now()}`"
                      v-model="block.showState"
                    />
                    <label
                      class="custom-control-label"
                      :for="`show-state-${Date.now()}`"
                    ></label>
                  </span>
                  <p class="m-0 editorStyleTitle">State/Region/Province</p>
                </div>
                <div class="">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium"
                      >Is State required
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`state-${Date.now()}`"
                        v-model="block.isStateRequired"
                      />
                      <label
                        class="custom-control-label"
                        :for="`state-${Date.now()}`"
                      ></label>
                    </span>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium">
                      Show Label
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`label-state-${Date.now()}`"
                        v-model="block.showStateLabel"
                      />
                      <label
                        class="custom-control-label"
                        :for="`label-state-${Date.now()}`"
                      ></label>
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-if="block.showStateLabel"
                  >
                    <strong>Label Text :</strong>
                    <b-form-input
                      placeholder="Address"
                      v-model="block.StateLabel"
                      class="w-50"
                    ></b-form-input>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-1"
                  >
                    <strong>Placeholder Text :</strong>
                    <b-form-input
                      placeholder="Address "
                      v-model="block.StatePlaceholder"
                      class="w-50"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2 border-bottom pb-3" :style="
                !block.showZipCode &&
                checkExistingAddresField(`zipCode`)
                ? 'opacity:0.4'
                  : null
              "
                >
              <div class="  ">
                <div class="d-flex align-items-center pb-2">
                  <span class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      :disabled="
                        !block.showZipCode &&
                        checkExistingAddresField(`zipCode`)
                      "
                      class="custom-control-input"
                      :id="`show-zip-${Date.now()}`"
                      v-model="block.showZipCode"
                    />
                    <label
                      class="custom-control-label"
                      :for="`show-zip-${Date.now()}`"
                    ></label>
                  </span>
                  <p class="m-0 editorStyleTitle">Zip/Post Code</p>
                </div>
                <div class="">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium"
                      >Is Zip Code required
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`zip-${Date.now()}`"
                        v-model="block.isZipCodeRequired"
                      />
                      <label
                        class="custom-control-label"
                        :for="`zip-${Date.now()}`"
                      ></label>
                    </span>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium">
                      Show Label
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`label-zipCode-${Date.now()}`"
                        v-model="block.showZipCodeLabel"
                      />
                      <label
                        class="custom-control-label"
                        :for="`label-zipCode-${Date.now()}`"
                      ></label>
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-if="block.showZipCodeLabel"
                  >
                    <strong>Label Text :</strong>
                    <b-form-input
                      placeholder="Address"
                      v-model="block.ZipCodeLabel"
                      class="w-50"
                    ></b-form-input>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-1"
                  >
                    <strong>Placeholder Text :</strong>
                    <b-form-input
                      placeholder="Address "
                      v-model="block.ZipCodePlaceholder"
                      class="w-50"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2 border-bottom pb-3" :style="
                !block.showCountry &&
                checkExistingAddresField(`Country`)
                ? 'opacity:0.4'
                  : null
              "
                >
              <div class="  ">
                <div class="d-flex align-items-center pb-2">
                  <span class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="
                        !block.showCountry &&
                        checkExistingAddresField(`Country`)
                      "
                      :id="`show-country-${Date.now()}`"
                      v-model="block.showCountry"
                    />
                    <label
                      class="custom-control-label"
                      :for="`show-country-${Date.now()}`"
                    ></label>
                  </span>
                  <p class="m-0 editorStyleTitle">Country</p>
                </div>

                <div class="">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium"
                      >Is Country required
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`country-${Date.now()}`"
                        v-model="block.isCountryRequired"
                      />
                      <label
                        class="custom-control-label"
                        :for="`country-${Date.now()}`"
                      ></label>
                    </span>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong class="text-dark font-weight-medium">
                      Show Label
                    </strong>
                    <span class="custom-control custom-switch mt-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`label-Country-${Date.now()}`"
                        v-model="block.showCountryLabel"
                      />
                      <label
                        class="custom-control-label"
                        :for="`label-Country-${Date.now()}`"
                      ></label>
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-if="block.showCountryLabel"
                  >
                    <strong>Label Text :</strong>
                    <b-form-input
                      placeholder="Address"
                      v-model="block.CountryLabel"
                      class="w-50"
                    ></b-form-input>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-1"
                  >
                    <strong>Placeholder Text :</strong>
                    <b-form-input
                      placeholder="Address "
                      v-model="block.CountryPlaceholder"
                      class="w-50"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <!--Field Setting End -->
    <!--Field Style  Start -->
    <div class="card-body px-0">
      <div id="LabelStyleSettingAccordion">
        <div class="headingText" id="headingfour">
          <strong class="">
            <span>Style Setting</span>

            <a
              block
              v-b-toggle.styleSetting
              style="float: right; padding-right: 20px; cursor: pointer"
              class="arrow-icon-down"
              data-toggle="collapse"
              data-target="#styleSetting"
              aria-expanded="false"
              aria-controls="styleSetting"
            >
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </a>
          </strong>
        </div>
        <hr />
        <b-collapse id="styleSetting" accordion="my-accordion" role="tabpanel">
          <div class="">
            <!-- Answer Styling -->

            <!-- Color Input start-->
            <div class="row my-2">
              <div class="col-5 d-flex align-items-center pr-0 px-2">
                <input
                  type="color"
                  v-model="block.style.color"
                  style="
                    min-width: 30px;
                    width: 30px;
                    height: 30px;
                    padding: 0px;
                  "
                  class="form-control-color form-control border-0"
                />
                <input
                  type="text"
                  class="ColorTextInput form-control-sm  input-border-style"
                  v-model="block.style.color"
                />
              </div>
              <div class="col-3 d-flex align-items-center px-0">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  v-model="block.style.textInputOpacity"
                  max="100"
                  min="0"
                  class="form-control-sm  input-border-style"
                />
              </div>
              <div
                class="col-4 d-flex align-items-center justify-content-around"
              >
                <span
                  :class="{
                    'active-svg': block.style.textInputAlign === 'left',
                  }"
                  type="button"
                  @click="block.style.textInputAlign = 'left'"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                      fill="#535355"
                    />
                  </svg>
                </span>
                <span
                  :class="{
                    'active-svg': block.style.textInputAlign === 'center',
                  }"
                  type="button"
                  @click="block.style.textInputAlign = 'center'"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  :class="{
                    'active-svg': block.style.textInputAlign === 'end',
                  }"
                  type="button"
                  @click="block.style.textInputAlign = 'end'"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <!-- Color Input end-->
            <!-- Stroke -->
            <div class="my-2">
              <div class="row mt-3">
                <div class="col-12">
                  <p class="text-dark font-weight-medium mb-0">
                    <strong>Stroke:</strong>
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <input
                    type="color"
                    style="
                      min-width: 30px;
                      width: 30px;
                      height: 30px;
                      padding: 0px;
                    "
                    v-model="block.style.strokeColor"
                    class="form-control-color form-control border-0"
                  />
                  <input
                    type="text"
                    class="ColorTextInput form-control-sm  input-border-style"
                    v-model="block.style.strokeColor"
                  />
                </div>
                <div class="d-flex align-items-center px-0">
                  <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                      fill="#B3AFB6"
                    />
                  </svg>

                  <input
                    type="number"
                    v-model="block.style.strokeOpacity"
                    class="form-control-sm  input-border-style ml-2"
                    style="width: 60px"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.92494 7.10834L4.74993 5.92501C3.99993 6.89168 3.53327 8.00834 3.3916 9.16668H5.07494C5.1916 8.44168 5.48327 7.73334 5.92494 7.10834ZM5.07494 10.8333H3.3916C3.53327 11.9917 3.9916 13.1083 4.7416 14.075L5.9166 12.8917C5.48327 12.2667 5.1916 11.5667 5.07494 10.8333ZM5.9166 15.2667C6.88327 16.0167 8.00827 16.4667 9.1666 16.6083V14.9167C8.4416 14.7917 7.7416 14.5083 7.1166 14.0583L5.9166 15.2667ZM10.8333 3.39168V0.833344L7.0416 4.62501L10.8333 8.33334V5.07501C13.1999 5.47501 14.9999 7.52501 14.9999 10C14.9999 12.475 13.1999 14.525 10.8333 14.925V16.6083C14.1249 16.2 16.6666 13.4 16.6666 10C16.6666 6.60001 14.1249 3.80001 10.8333 3.39168Z"
                      fill="#B3AFB6"
                    />
                  </svg>

                  <input
                    type="number"
                    min="0"
                    v-model="block.style.strokeRotation"
                    class="form-control-sm border-0 ml-2"
                    style="width: 60px"
                  />
                </div>
                <div
                  class="d-flex align-items-center d-none"
                  style="visibility: hidden"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.24999 10C3.24999 8.57504 4.40832 7.41671 5.83332 7.41671H9.16666V5.83337H5.83332C3.53332 5.83337 1.66666 7.70004 1.66666 10C1.66666 12.3 3.53332 14.1667 5.83332 14.1667H9.16666V12.5834H5.83332C4.40832 12.5834 3.24999 11.425 3.24999 10ZM6.66666 10.8334H13.3333V9.16671H6.66666V10.8334ZM14.1667 5.83337H10.8333V7.41671H14.1667C15.5917 7.41671 16.75 8.57504 16.75 10C16.75 11.425 15.5917 12.5834 14.1667 12.5834H10.8333V14.1667H14.1667C16.4667 14.1667 18.3333 12.3 18.3333 10C18.3333 7.70004 16.4667 5.83337 14.1667 5.83337Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <!-- border Inputs -->
            <div>
              <div class="row my-2">
                <div
                  class="col-4 d-flex justify-content-between align-items-center"
                >
                  <button
                    class="btn"
                    :class="{
                      'active-svg': block.style.borderType === 'border',
                    }"
                    @click="block.style.borderType = 'border'"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667ZM13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H13C13.4583 2.16667 13.8333 2.54167 13.8333 3V13C13.8333 13.4583 13.4583 13.8333 13 13.8333Z"
                        fill="#292929"
                      />
                    </svg>
                  </button>
                  <button
                    class="btn"
                    :class="{
                      'active-svg': block.style.borderType === 'border-bottom',
                    }"
                    @click="block.style.borderType = 'border-bottom'"
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.5 9.16667H4.16667V10.8333H2.5V9.16667ZM2.5 12.5H4.16667V14.1667H2.5V12.5ZM2.5 15.8333H17.5V17.5H2.5V15.8333ZM15.8333 12.5H17.5V14.1667H15.8333V12.5ZM2.5 5.83333H4.16667V7.5H2.5V5.83333ZM15.8333 9.16667H17.5V10.8333H15.8333V9.16667ZM15.8333 2.5H17.5V4.16667H15.8333V2.5ZM15.8333 5.83333H17.5V7.5H15.8333V5.83333ZM12.5 2.5H14.1667V4.16667H12.5V2.5ZM2.5 2.5H4.16667V4.16667H2.5V2.5ZM5.83333 2.5H7.5V4.16667H5.83333V2.5ZM9.16667 2.5H10.8333V4.16667H9.16667V2.5Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </button>
                </div>
                <div class="d-flex align-items-center col-4 px-0">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" fill="white" />
                    <path
                      d="M2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667ZM15 15.8333H5C4.54167 15.8333 4.16667 15.4583 4.16667 15V5C4.16667 4.54167 4.54167 4.16667 5 4.16667H15C15.4583 4.16667 15.8333 4.54167 15.8333 5V15C15.8333 15.4583 15.4583 15.8333 15 15.8333Z"
                      fill="#B3AFB6"
                    />
                  </svg>

                  <input
                    type="number"
                    min="1"
                    max="10"
                    v-model="block.style.borderSize"
                    class="form-control-sm  input-border-style ml-2"
                    style="max-width: 50px"
                  />
                </div>
                <div class="d-flex align-items-center col-4 px-0">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.8333 15.8333H17.5V17.5H15.8333V15.8333ZM15.8333 14.1667H17.5V12.5H15.8333V14.1667ZM2.5 10.8333H4.16667V9.16667H2.5V10.8333ZM2.5 14.1667H4.16667V12.5H2.5V14.1667ZM2.5 7.5H4.16667V5.83333H2.5V7.5ZM2.5 4.16667H4.16667V2.5H2.5V4.16667ZM5.83333 4.16667H7.5V2.5H5.83333V4.16667ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM9.16667 17.5H10.8333V15.8333H9.16667V17.5ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM5.83333 17.5H7.5V15.8333H5.83333V17.5ZM2.5 17.5H4.16667V15.8333H2.5V17.5ZM17.5 6.66667C17.5 4.36667 15.6333 2.5 13.3333 2.5H9.16667V4.16667H13.3333C14.7083 4.16667 15.8333 5.29167 15.8333 6.66667V10.8333H17.5V6.66667Z"
                      fill="#B3AFB6"
                    />
                  </svg>

                  <input
                    type="number"
                    min="0"
                    v-model="block.style.borderRadius"
                    class="form-control-sm  input-border-style ml-2"
                    style="max-width: 50px"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <!--Field Style End -->

    <div>
      <!-- Margin -->

      <div class="row m-0" v-if="isDesktopView">
        <h4 class="editorStyleTitle mt-4">Margins:</h4>
        <div class="row align-items-center">
          <div class="col-6 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginTop"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="col-6 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginBottom"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="form-group col-6 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginLeft"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-6 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginRight"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2" v-else>
        <h4 class="editorStyleTitle mt-4">Mobile margin:</h4>
        <div class="row m-0">
          <div class="row align-items-center">
            <div class="col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    min="0"
                    placeholder="Top"
                    v-model="block.style.mobileMarginTop"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
            <div class="col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    min="0"
                    placeholder="Bottom"
                    v-model="block.style.mobileMarginBottom"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="form-group col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.mobileMarginLeft"
                    min="0"
                    placeholder="Left"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
            <div class="form-group col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.mobileMarginRight"
                    min="0"
                    placeholder="Right"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    block: Object,
    isDesktopView: Boolean,
    addedBlocks: Array,
  },
  data() {
    return {};
  },

  methods: {
    checkExistingAddresField(input) {
      let allInputs = [...this.addedBlocks];
      if (allInputs.length) {
        return allInputs.includes(input);
      }

      return false;
    },
  },
  computed: {},
};
</script>
<style scoped>
.headingText strong {
  font-size: 14px;
  font-weight: 500 !important;
}
.reset-option {
  border: 0.3px solid #6d6b6d;
  box-sizing: border-box;
  border-radius: 4px;
}
/* .editorStyleTitle input {
  border-color: #b3afb6;
  font-size: 14px;
}
.editorStyleTitle {
  font-weight: 400 !important;
} */
.text-setting select {
  font-size: 14px;
  padding: 0;
}
.ql-container.ql-snow {
  background: white;
}
.button-group {
  color: #535353;
  outline: #c4c4c4 solid 1px;
}
.button-selected {
  background: #000000 !important;
  color: #ffffff !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
            background-color: #ffc107 !important; 
            border-color: #ffc107 !important; 
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #fff !important; 
        }

/* .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
} */

/* Button Group CSS Style */

.productViewSelected {
  background: #ffa201 !important;
  color: #ffffff !important;
}
</style>
